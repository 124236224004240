<template>
  <div class="list_container">
    <div class="header">
      <div class="picker">
        <van-field readonly clickable label="选择订单日期" :value="date" @click="datePickerShow = true" right-icon="arrow-down"
          class="picker" />
        <van-popup v-model="datePickerShow" round position="bottom">
          <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
            :formatter="formatter" @confirm="dateConfirm" @cancel="dateCancel" />
        </van-popup>
      </div>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">温馨提示：默认查询7日内的住院清单记录</van-notice-bar>
    </div>
    <div class="info">
      <van-cell title="科室" :value="hospitalizationFeesListSummary.deptName" :border="false" />
      <van-cell title="床位" :value="hospitalizationFeesListSummary.bedNo === '' ? '-' : hospitalizationFeesListSummary.bedNo"
        :border="false" />
      <van-cell title="入院时间" :value="hospitalizationFeesListSummary.inDate" :border="false" />
      <van-cell title="出院时间"
        :value="hospitalizationFeesListSummary.outDate === '' || hospitalizationFeesListSummary.outDate === '0001-01-01' ? '-' : hospitalizationFeesListSummary.outDate"
        :border="false" />
      <van-cell title="住院总费用" :value="hospitalizationFeesListSummary.totalFee + '元'" :border="false" />
    </div>
    <van-cell title="住院日清单列表" class="title" icon="orders-o" />
    <div class="list">
      <van-cell v-for="(it, index) in hospitalizationFeesListSummary.item" :key="index" :title="'费用日期：' + it.itemDate"
        :label="'当天费用价格合计：' + it.itemTotalFee + '元'" is-link @click="navDetail(it.itemDate)" />
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "List",
  data () {
    return {
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      date: "",
      datePickerShow: false,
      end: "",
      admissionNum: "",
      patCardNo: "",
      patCardType: "",
      hospitalizationFeesListSummary: {},
      inDate: ""
    };
  },
  created () {
    this.admissionNum = this.$route.query.admissionNum;
    this.patCardNo = this.$route.query.patCardNo;
    this.patCardType = this.$route.query.patCardType;
    this.inDate = this.$route.query.inDate;
    this.date = this.formatTime(-6);
    this.end = this.formatTime(0);
    this.getHospitalizationFeesListSummary();
  },
  methods: {
    formatter (type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    formatTime (num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let currentShowTime = nowTime + -6 * oneDayTime;
      let myDate = new Date(ShowTime);
      let currentDate = new Date(currentShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      this.currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return y + "-" + m + "-" + d;
    },
    dateFormat (dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      return time;
    },
    dateConfirm (value) {
      this.date = this.dateFormat(value);
      this.datePickerShow = false;
      this.getHospitalizationFeesListSummary();
    },
    dateCancel () {
      this.datePickerShow = false;
    },
    async getHospitalizationFeesListSummary () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/his/getHospitalizationFeesListSummary",
        {
          patCardNo: this.patCardNo,
          patCardType: this.patCardType,
          admissionNum: this.admissionNum,
          beginDate: this.inDate,
          endDate: this.end
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.hospitalizationFeesListSummary = res.data;
      } else {
        this.$toast.fail(res.msg);
      }
    },
    navDetail (itemDate) {
      this.$router.push({
        path: "/inpatient/detail",
        query: {
          patCardType: this.patCardType,
          patCardNo: this.patCardNo,
          admissionNum: this.admissionNum,
          itemDate: itemDate
        }
      });
    }
  }
};
</script>

<style scope>
.list_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.list_container .header .picker .van-cell {
  padding: 30px;
}

.list_container .header .picker .van-cell::after {
  border: 0;
}

.list_container .header .picker .van-field__label {
  text-align: left;
  font-size: 40px;
}

.list_container .header .picker .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.list_container .header .picker .van-icon {
  font-size: 40px;
}

.list_container .header .picker .van-picker-column {
  font-size: 40px;
}

.list_container .header .picker .van-picker__toolbar {
  height: 150px;
}

.list_container .header .picker .van-picker__cancel,
.list_container .header .picker .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.list_container .header .van-notice-bar__content {
  font-size: 32px;
}

.list_container .header .van-notice-bar__left-icon {
  font-size: 32px;
}

.list_container .info {
  border-bottom: 2px solid #f5f5f5;
  padding: 20px 0;
}

.list_container .info .van-cell {
  font-size: 40px;
}

.list_container .title {
  font-size: 40px;
  border-bottom: 2px solid #f5f5f5;
}

.list_container .title .van-icon {
  font-size: 40px;
  color: #1989fa;
}

.list_container .list {
  padding-bottom: 60px;
}

.list_container .list .van-cell {
  padding: 30px;
  border-bottom: 1px solid #f5f5f5;
}

.list_container .list .van-cell__title {
  font-size: 40px;
}

.list_container .list .van-cell__label {
  font-size: 40px;
  margin-top: 20px;
}

.list_container .list .van-icon {
  font-size: 40px;
}
</style>

